import React from 'react';
import { IconInterface } from './Icon.interface';

const Selenium: React.FC<IconInterface> = ({size = 24}) => {
	return (
		<span className='i-icon'>
			<svg xmlns="http://www.w3.org/2000/svg"  viewBox={'0 0 50 50'} width={size} height={size} fill="none">
				<path fill="currentColor" d="M47.49,4.67l-3.41-3.21l-1.47-1.39l-1.37,1.49L38.99,4h-8c-0.02,0-0.04,0-0.06,0.01C30.91,4,30.9,4,30.88,4H9 C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9.22l1.6-1.74l1.33-1.46L47.49,4.67z M14.45,40 c-3.72,0-6.27-1.98-6.45-5h2.55c0.27,1.64,1.81,2.62,4.09,2.62c2.09,0,3.63-1.09,3.63-2.65c0-1.37-0.91-2.1-3.18-2.65l-2.18-0.54 c-3-0.73-4.46-2.29-4.46-4.75c0-3.02,2.46-5.03,6.19-5.03c3.45,0,6,2.01,6.09,4.93h-2.55c-0.27-1.55-1.63-2.55-3.63-2.55 c-2.1,0-3.46,1-3.46,2.55c0,1.28,0.91,1.92,3.09,2.47l1.91,0.46C19.55,30.68,21,32.14,21,34.7C21,37.99,18.45,40,14.45,40z M36,34 h-9.38v0.13c0.1,2.12,1.5,3.75,3.57,3.75c1.5,0,2.62-0.53,3.09-1.5h2.53C35.25,38.67,33.09,40,30.09,40C26.34,40,24,37.52,24,33.54 S26.34,27,30.09,27c3.66,0,5.91,2.39,5.91,6.28V34z M29.73,24L19,13.8l3.23-3.4l7.31,6.8L42.71,2.91l3.41,3.21L29.73,24z M30.09,29.05c-1.85,0-3.12,1.18-3.34,2.95h6.59C33.14,30.16,31.95,29.05,30.09,29.05z" />
			</svg>
		</span>
	);
}

export default Selenium;
