import React from 'react';
import { IconInterface } from './Icon.interface';

const GraphQL: React.FC<IconInterface> = ({size = 24}) => {
	return (
		<span className='i-icon'>
			<svg xmlns="http://www.w3.org/2000/svg"  viewBox={'0 0 24 24'} width={size} height={size} fill="none">
				<path fill="currentColor" d="M4.657,5.218C3.649,4.626,2.361,4.976,1.779,6C1.68,6.177,1.605,6.368,1.559,6.566C1.287,7.717,1.983,8.874,3.113,9.15	l-0.001,5.699c-0.391,0.097-0.748,0.306-1.026,0.603c-0.802,0.855-0.771,2.21,0.069,3.026c0.841,0.817,2.173,0.784,2.975-0.07	l4.853,2.847c-0.058,0.199-0.087,0.404-0.087,0.609C9.897,23.045,10.839,24.001,12,24c0.224,0,0.445-0.037,0.659-0.108	c1.104-0.371,1.704-1.582,1.338-2.706l4.822-2.832c0.149,0.17,0.325,0.316,0.517,0.429c1.009,0.592,2.296,0.241,2.878-0.782	c0.103-0.181,0.181-0.377,0.228-0.581c0.269-1.146-0.427-2.296-1.553-2.569V9.152c0.194-0.048,0.378-0.123,0.549-0.223	c1.011-0.589,1.362-1.9,0.782-2.928c-0.101-0.176-0.225-0.337-0.371-0.477c-0.844-0.816-2.179-0.782-2.981,0.077l-4.852-2.85	c0.058-0.199,0.089-0.408,0.088-0.617C14.103,0.954,13.161-0.001,12,0c-0.203,0-0.406,0.029-0.6,0.088	c-1.111,0.335-1.747,1.524-1.417,2.657l-4.855,2.85C4.99,5.446,4.831,5.319,4.657,5.218z M13.556,20.421	c-0.386-0.429-0.94-0.698-1.556-0.698c-0.596,0-1.133,0.249-1.514,0.653L5.63,17.524l0.021-0.083h12.698	c0.011,0.048,0.023,0.095,0.037,0.142L13.556,20.421z M13.51,3.628l4.863,2.858c-0.007,0.023-0.013,0.048-0.019,0.072	c-0.279,1.146,0.406,2.305,1.532,2.588v5.707c-0.026,0.007-0.055,0.014-0.081,0.023l-6.352-11.19	C13.474,3.667,13.492,3.649,13.51,3.628z M11.415,4.194c0.382,0.112,0.788,0.112,1.172,0L18.94,15.38	c-0.291,0.283-0.497,0.646-0.593,1.044H5.654c-0.096-0.396-0.301-0.758-0.59-1.041L11.415,4.194z M5.624,6.479l4.863-2.852	l0.059,0.06l-6.35,11.189l-0.083-0.023V9.145c0.025-0.007,0.049-0.013,0.075-0.021C5.303,8.797,5.946,7.614,5.624,6.479z" />
			</svg>
		</span>
	);
}

export default GraphQL;
