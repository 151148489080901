import React from 'react';
import { IconInterface } from './Icon.interface';

const WebpackIcon: React.FC<IconInterface> = ({size = 24}) => {
	return (
		<span className='i-icon'>
			<svg xmlns="http://www.w3.org/2000/svg"  viewBox={'0 0 24 24'} width={size} height={size} fill="none">
				<path fill="currentColor" d="M 11.566406 0 L 1.4238281 5.6445312 L 5.0195312 7.6855469 L 11.566406 4.1445312 L 11.566406 0 z M 12.433594 0 L 12.433594 4.1445312 L 18.980469 7.6875 L 22.576172 5.6445312 L 12.433594 0 z M 12.001953 4.8828125 L 5.8984375 8.1855469 L 12.001953 11.650391 L 18.105469 8.1855469 L 12.001953 4.8828125 z M 1 6.390625 L 1 17.896484 L 4.6191406 15.839844 L 4.6191406 8.4433594 L 1 6.390625 z M 23 6.390625 L 19.380859 8.4433594 L 19.380859 15.839844 L 23 17.896484 L 23 6.390625 z M 5.4882812 8.9394531 L 5.4882812 15.451172 L 11.566406 18.740234 L 11.566406 12.390625 L 5.4882812 8.9394531 z M 18.513672 8.9394531 L 12.433594 12.390625 L 12.433594 18.740234 L 18.513672 15.451172 L 18.513672 8.9394531 z M 5.4042969 16.380859 L 1.6777344 18.498047 L 11.566406 24 L 11.566406 19.714844 L 5.4042969 16.380859 z M 18.597656 16.380859 L 12.433594 19.714844 L 12.433594 24 L 22.324219 18.498047 L 18.597656 16.380859 z" />
			</svg>
		</span>
	);
}

export default WebpackIcon;
