import React from 'react';
import { IconInterface } from './Icon.interface';

const D3: React.FC<IconInterface> = ({size = 24}) => {
	return (
		<span className='i-icon'>
			<svg xmlns="http://www.w3.org/2000/svg"  viewBox={'0 0 48 48'} width={size} height={size} fill="none">
				<path fill="currentColor" d="M 45 2 C 43.894531 2 43 2.894531 43 4 C 43 4.292969 43.074219 4.5625 43.1875 4.8125 L 35.1875 16 C 35.125 15.996094 35.0625 16 35 16 C 34.601563 16 34.21875 16.109375 33.90625 16.3125 L 27 12.875 C 26.933594 11.828125 26.0625 11 25 11 C 23.894531 11 23 11.894531 23 13 C 23 13.105469 23.015625 13.210938 23.03125 13.3125 L 15.71875 19.125 C 15.496094 19.039063 15.253906 19 15 19 C 14 19 13.179688 19.730469 13.03125 20.6875 L 6.21875 23.4375 C 5.878906 23.171875 5.460938 23 5 23 C 3.894531 23 3 23.894531 3 25 C 3 26.105469 3.894531 27 5 27 C 6.007813 27 6.832031 26.253906 6.96875 25.28125 L 13.78125 22.5625 C 14.121094 22.828125 14.539063 23 15 23 C 16.105469 23 17 22.105469 17 21 C 17 20.894531 16.984375 20.789063 16.96875 20.6875 L 24.28125 14.875 C 24.503906 14.960938 24.746094 15 25 15 C 25.398438 15 25.78125 14.890625 26.09375 14.6875 L 33 18.125 C 33.066406 19.171875 33.9375 20 35 20 C 36.105469 20 37 19.105469 37 18 C 37 17.707031 36.925781 17.4375 36.8125 17.1875 L 44.8125 6 C 44.875 6.003906 44.9375 6 45 6 C 46.105469 6 47 5.105469 47 4 C 47 2.894531 46.105469 2 45 2 Z M 41 15 L 41 50 L 49 50 L 49 15 Z M 21 24 L 21 50 L 29 50 L 29 24 Z M 31 29 L 31 50 L 39 50 L 39 29 Z M 11 32 L 11 50 L 19 50 L 19 32 Z M 1 36 L 1 50 L 9 50 L 9 36 Z" />
			</svg>
		</span>
	);
}

export default D3;
